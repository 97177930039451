<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div
              class="wizard-step ml-3"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <!-- <div class="wizard-wrapper"> -->
                <img :src="detail.photo" width="200" alt="" />
              <!-- </div> -->
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5 class="font-weight-bold text-dark">Detail Admin TU Prodi</h5>

                  <div class="card-body">
                    <table class="table table-borderless">
                      <tr>                      
                        <th class="pr-1" width="130"><span>Nama</span></th>
                        <th class="pl-0"><span>:</span></th>
                        <td class="pl-2"><span>{{detail.name}}</span></td>
                      </tr>
                      <tr>                      
                        <th class="pr-1" width="130"><span>Nick Name</span></th>
                        <th class="pl-0"><span>:</span></th>
                        <td class="pl-2"><span>{{detail.nickname}}</span></td>
                      </tr>
                      <tr>                      
                        <th class="pr-0"><span>Telepon</span></th>
                        <th class="pl-0"><span>:</span></th>
                        <td class="pl-2"><span>{{detail.phone}}</span></td>
                      </tr>
                      <tr>                      
                        <th class="pr-0"><span>Email</span></th>
                        <th class="pl-0"><span>:</span></th>
                        <td class="pl-2"><span>{{detail.email2}}</span></td>
                      </tr>
                      <tr>                      
                        <th class="pr-0"><span>Alamat</span></th>
                        <th class="pl-0"><span>:</span></th>
                        <td class="pl-2"><span>{{detail.address}}</span></td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div>
                    <b-button
                      type="button"
                      class="ml-2"
                      variant="primary"
                      @click="$router.push('/masters/users/admin-tu-prodi')"
                    >
                      Tutup
                    </b-button>
                    <b-button
                      type="button"
                      class="ml-2"
                      variant="success"
                      @click="$router.push('/masters/users/admin-tu-prodi/edit/' + $route.params.id)"
                      v-if="btnAccess"
                    >
                      Edit
                    </b-button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<style>
.wizard-steps{
  height: 230px;
  width: 230px;
}

.wizard-steps img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";

export default {
  name: "master-topics-detail",
  data() {
    return {
      detail: {},
      // access
      btnAccess: false
    };
  },
  methods: {
    getUserById() {
      ApiService.get("api/users/" + this.$route.params.id)
        .then((response) => {
          //(response);
          this.detail = response.data.data;
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Admin TU Prodi", route: "/masters/users/admin-tu-prodi" },
      { title: "Detail Admin TU Prodi" },
    ]);

    this.getUserById();
    this.checkAccess();
  },
};
</script>
